// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

function watchForHover() {
  // lastTouchTime is used for ignoring emulated mousemove events
  let lastTouchTime = 0;

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return;
    document.body.classList.add('hasHover');
  }

  function disableHover() {
    document.body.classList.remove('hasHover');
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date();
  }

  document.addEventListener('touchstart', updateLastTouchTime, true);
  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  enableHover();
}

watchForHover();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function headerHeight() {
  const headerHeight = $('header.header .header-wrapper').outerHeight();
  $('body').css('--header-height', `${headerHeight}px`);
}

function headerOnScroll() {
  if (window.scrollY === 0) $('header.header').removeClass('scrolling');
  else $('header.header').addClass('scrolling');
}

function headerMenu() {
  const openMenuButton = $('#menu-open');
  const closeMenuButton = $('#menu-close');
  const menuOverlay = $('#menu-overlay');
  const menu = menuOverlay.find('.js-main-menu');
  const menuWrap = menu.closest('.main-nav');
  const menuItems = menu.find('.menu-item');

  const openMenu = () => {
    menuOverlay.addClass('active');
    menu.css('min-height', menuWrap.height());
    $('html, body').css('overflow', 'hidden');
  };

  const closeMenu = () => {
    menuOverlay.removeClass('active');
    $('html, body').css('overflow', '');
  };

  openMenuButton.on(
    'click',
    () => !menuOverlay.hasClass('active') && openMenu()
  );

  closeMenuButton.on(
    'click',
    () => menuOverlay.hasClass('active') && closeMenu()
  );

  menuItems.find('> a').on('click', function (e) {
    const $this = $(this);
    const thisMenuItem = $this.closest('.menu-item');
    const levelMenuItems = $this.closest('ul').find('> .menu-item');
    const thisSubmenu = $this.siblings('.sub-menu');
    if (thisSubmenu.length) {
      e.preventDefault();
      if (!thisMenuItem.hasClass('active')) {
        levelMenuItems.fadeOut(200, () => {
          thisMenuItem.addClass('active');
          thisMenuItem.fadeIn(400);
        });
      } else {
        thisMenuItem.fadeOut(200, () => {
          thisMenuItem.removeClass('active');
          levelMenuItems.fadeIn(400);
        });
      }
    }
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  headerMenu();
  headerHeight();
  headerOnScroll();

  $('.gf-letter-only input').bind('keyup blur', function () {
    var node = $(this);
    node.val(node.val().replace(/[^A-z ]/g, ''));
  });
  //Start Ajax Filter Projects
  //Caategory List
  var cat_btns = $('.all-cat');
  for (var i = 0; i < cat_btns.length; i++) {
    cat_btns[i].addEventListener('click', function () {
      for (var j = 0; j < cat_btns.length; j++) {
        cat_btns[j].classList.remove('is-active');
      }
      this.classList.add('is-active');
    });
  }

  //ajax-filter
  function project_filter(elem) {
    let category = elem.attr('filter');
    $.ajax({
      type: 'POST',
      /* eslint-disable-next-line */
      url: ajax_object.ajax_url, //get from wp_localize_script()
      data: {
        action: 'filter_posts', // action for wp_ajax_ and wp_ajax_nopriv_
        cat: category,
      },
      beforeSend: function () {},
      success: function (data) {
        $('.project-wrap').html(data.data);
      },
    });
  }

  $('.filter-projects').on('click', function () {
    project_filter($(this));
  });
  //  end filter

  $('.portfolio-gallery').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    speed: 2000,
    autoplaySpeed: 9000,
    autoplay: true,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 2,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1026,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 2,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  /* ============ News Slider ============ */
  $('.news-gallery').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 9000,
    autoplay: true,
    dots: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1990,
        settings: {
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1441,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1026,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 460,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2000,
          autoplaySpeed: 9000,
          autoplay: true,
          dots: true,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  });
  /* ============ Testimonials Slider ============ */
  $('.testimonial-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 9000,
    autoplay: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          speed: 1000,
          autoplaySpeed: 7500,
          autoplay: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          speed: 1000,
          autoplaySpeed: 5000,
          autoplay: true,
        },
      },
    ],
  });

  $('.js-logo-slider').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 1000,
    dots: false,
    arrows: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  headerHeight();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */

$(window).on('scroll', function () {
  // jQuery code goes here
  headerOnScroll();
});
